import { Container } from "./styles";

export default function ActiveCampaignForm() {
  return (
    <Container>
      <div className="boxes">
        <a
          rel="noreferrer"
          href="https://checkout.kenzie.com.br/pay/codigo-da-virada"
          id="_form_63_submit"
          target="_blank"
        >
          Quero programar
        </a>
      </div>

      <p className="terms">
        Na qualidade de Usuário declaro que li, compreendi e estou de acordo com
        os{" "}
        <a
          href="https://formulario-kenzie.vercel.app/termos-condicoes"
          target="_blank"
          rel="noreferrer"
        >
          Termos de Uso e com a Política de Privacidade
        </a>
        .
      </p>
    </Container>
  );
}
