import { MdExpandMore } from "react-icons/md";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { Container, Content, List } from "./styles";

export default function FAQ() {
  return (
    <Container>
      <h2 className="title">PERGUNTAS FREQUENTES</h2>
      <Content>
        <Accordion>
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>
              <span>01.</span> Para quem é o Código da Virada?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <li>
                Para quem deseja entrar na área mais aquecida do mercado de
                trabalho.
              </li>
              <li>
                Para quem não tem conhecimento prévio de programação ou quer se
                aperfeiçoar como dev.
              </li>
              <li>
                Para quem quer começar a carreira com salários acima dos R$
                2.500,00.
              </li>
              <li>
                Para quem quer ter acesso a um conteúdo completo de programação
                e realizar um projeto do total zero.
              </li>
              <li>
                Para quem não tem medo de desafios e cansou de apenas sonhar em
                ser programador(a).
              </li>
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>
              <span>02.</span> Preciso ter conhecimento na área para entrar no
              Código da Virada?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Não é necessário conhecimento prévio em programação. Durante o
              Código da Virada, você terá acesso a todo conteúdo técnico e
              prático para avançar nos exercícios e conquistar o seu
              certificado, além de um projeto pronto para o seu portfólio.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <h3>
              <span>03.</span> Preciso saber inglês?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Não exigimos um segundo idioma. Todos os materiais usados durante
              o Código da Virada são em português ou traduzidos.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <h3>
              <span>04.</span> Vai ter certificado?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Sim. Vai ser disponibilizado certificado para quem cumprir com as
              regras de participação e exercícios do desafio.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <h3>
              <span>05.</span> Quem é a Kenzie Academy, idealizadora do Código
              da Virada?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              A Kenzie Academy é uma escola norte-americana, fundada em 2017 e
              com centenas de alunos, que veio para o Brasil para ofertar um
              ensino de qualidade para quem deseja trabalhar na área da
              tecnologia. Com o foco na colocação profissional dos alunos, a
              Kenzie oferece as habilidades técnicas (principais linguagens e
              tecnologias do mercado) e as soft skills para o desenvolvimento de
              um programador ou uma programadora.
            </p>
          </AccordionDetails>
        </Accordion>
      </Content>
    </Container>
  );
}
