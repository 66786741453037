import {
  Container,
  MatrixContent,
  TextContainer,
  StyledStrong,
  Content,
  TitleDesktop,
} from "./styles";
import ImgMatrixSection from "../../assets/img/img-matrix-section.webp";

export default function MatrixSection() {
  return (
    <Container>
      <TitleDesktop>A MATRIX TE PRENDEU... ATÉ HOJE</TitleDesktop>
      <Content>
        <MatrixContent>
          <img
            src={ImgMatrixSection}
            alt="Imagem de pílulas"
            width="221"
            height="220"
          />
          <h2>A MATRIX TE PRENDEU... ATÉ HOJE</h2>
        </MatrixContent>
        <TextContainer>
          <p>Você esteve dormindo nos últimos anos...</p>

          <p>
            Todas as vezes que você desejou se tornar um(a) programador(a) e não
            fez nada, você estava dormindo. Sonhando apenas. Porém, isso acaba
            HOJE.
          </p>

          <p>
            A Kenzie preparou um <strong> desafio</strong> para você despertar
            da Matrix e dar os primeiros passos no mercado de tecnologia, o mais
            lucrativo e promissor da atualidade.
          </p>
          <StyledStrong>Acorde: Você está pronto!</StyledStrong>
        </TextContainer>
      </Content>
    </Container>
  );
}
