import AndreImg from "../../assets/img/testimonials/student-andre.webp";
import AnneliseImg from "../../assets/img/testimonials/student-annelise.webp";
import IgorImg from "../../assets/img/testimonials/student-igor.webp";
import JoyceImg from "../../assets/img/testimonials/student-joyce.webp";
import NaldoImg from "../../assets/img/testimonials/student-naldo.webp";
import SusimaraImg from "../../assets/img/testimonials/student-susimara.webp";

export const testimonialsC1 = [
  {
    name: "Annelise Rocha",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“Sou da turma 13 e posso dizer, com certeza, que o conteúdo é muito completo, é uma formação de excelência! Em 1 mês se aprende mais coisa do que em um semestre em cursos tradicionais, é realmente enriquecedor!”",
    ],
    image: AnneliseImg,
  },
  {
    name: "Naldo Aragon",
    genre: "masculino",
    role: "Aluno da Kenzie",
    testimonial: [
      "“A Kenzie oferece uma didática de ensino diferente do que estamos acostumados. Aqui temos muitos exercícios práticos. A forma como o conteúdo é passado com material escrito, vídeos, projetos práticos é um diferencial.”",
    ],
    image: NaldoImg,
  },
];

export const testimonialsC2 = [
  {
    name: "Igor Ribeiro",
    genre: "masculino",
    role: "Aluno da Kenzie",
    testimonial: [
      "“Eu entrei na Kenzie sem saber nada de programação, aprendi do zero. Eu vi na Kenzie uma oportunidade de ter uma carreira promissora investindo apenas 1 ano de estudos para iniciar. Sempre gostei muito do suporte oferecido aos alunos e hoje já trabalhando na área eu posso dizer que sou outra pessoa, o que aprendi na Kenzie me abriu portas para muitas outras coisas.”",
    ],

    image: IgorImg,
  },
  {
    name: "Jacqueline Yumi Kajiya",
    genre: "feminino",
    role: "Aluna da Kenzie",
    video: "QtpKLf7zCXI",
    videoHeight: 34,
  },
];

export const testimonialsC3 = [
  {
    name: "Etnan Souza",
    genre: "masculino",
    role: "Aluno da Kenzie",
    video: "6oLD1gJSQlQ",
    videoHeight: 34,
  },
  {
    name: "Joyce Pereira",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“Entrei na Kenzie pois vi que aqui era onde realmente se aprendia programação, antes de decidir tinha optado por uma faculdade porém o tempo era muito longo, e hoje estou trabalhando em uma empresa maravilhosa onde realmente me sinto feliz, a Kenzie tem uma didática incrível de aprendizado que me fez crescer rapidamente.    ",
    ],
    image: JoyceImg,
  },
];

export const testimonialsC4 = [
  {
    name: "Susimara Luíza",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“O modelo de ensino focado em prática é uma das coisas que mais deram certo para o meu aprendizado, e junto disso a rotina de estudos tendo atividades todos os dias de semana aliada a disciplina que a Kenzie ensina ao aluno a construir.”",
    ],
    image: SusimaraImg,
  },
  {
    name: "André Luis Passoni",
    genre: "masculino",
    role: "Aluno da Kenzie",
    testimonial: [
      "“Quando adentrei o mercado de trabalho, que foi graças a conexões que realizei dentro do ecossistema da Kenzie, também percebi que o conhecimento técnico que nos foi passado é realmente de excelente qualidade.”",
    ],
    image: AndreImg,
  },
];
