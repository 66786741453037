import { useEffect, useState } from "react";
import { Container, StyledHeader } from "./styles";
import logo from "../../assets/img/logo.webp";
import logoBlack from "../../assets/img/logo-black.webp";

interface HeaderProps {
  onClick: () => void;
}

export default function Header({ onClick }: HeaderProps) {
  const [isFixed, setIsFixed] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    });
  }, []);

  return (
    <StyledHeader fixed={isFixed}>
      <Container>
        <img
          src={isFixed ? logoBlack : logo}
          alt="Logo Kenzie"
          width="95"
          height="83"
        />
        <button onClick={onClick}>Quero programar</button>
      </Container>
    </StyledHeader>
  );
}
