import { StudentTestimonial } from "../../shared/models/student";
import {
  Container,
  TestimonialHeader,
  TestimonialContent,
  TitleContent,
} from "./styles";

import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { useState } from "react";

interface TestimonialCardProps {
  student: StudentTestimonial;
  height?: number;
}

export default function TestimonialCard({
  student,
  height,
}: TestimonialCardProps) {
  const [mask, setMask] = useState(true);
  function removeMask() {
    setMask(false);
  }

  return (
    <>
      {student.video ? (
        <Container
          student={student}
          height={height}
          onClick={() => removeMask()}
        >
          <LiteYouTubeEmbed
            id={student.video}
            title={`Video de depoimento - ${student.name}`}
            poster="mqdefault"
            params="rel=0"
          />
          <TitleContent student={student} active={mask}>
            <h3>{student.name}</h3>
            <span>{student.role}</span>
          </TitleContent>
        </Container>
      ) : (
        <Container student={student}>
          <TestimonialHeader>
            <img
              src={student.image}
              width="43"
              height="43"
              alt={`Foto ${
                student.genre === "masculino" ? "do aluno" : "da aluna"
              } ${student.name}`}
            />
            <TitleContent student={student} active={mask}>
              <h3>{student.name}</h3>
              <span> {`${student.role}`}</span>
            </TitleContent>
          </TestimonialHeader>
          <TestimonialContent>{student.testimonial}</TestimonialContent>
        </Container>
      )}
    </>
  );
}
